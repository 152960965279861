<template>
  <div div="space">
    <YieldList
      :spaceId="space.Id"
      :channelId="channelId"
      :locationId="locationId"
      :meetingtypeId="meetingtypeId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const YieldList = () => import('@/components/Yield/YieldList')

export default {
  components: {
    YieldList,
  },

  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      showEditSpacePrices: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  created() {},

  methods: {},
}
</script>
